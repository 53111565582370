@tailwind base;
@tailwind components;
@tailwind utilities;

/*default colors for dark theme */
/*@layer base {*/
/*  :root {*/
/*    --background: 0 0% 100%;*/
/*    --foreground: 0 0% 3.9%;*/
/*    --card: 0 0% 100%;*/
/*    --card-foreground: 0 0% 3.9%;*/
/*    --popover: 0 0% 100%;*/
/*    --popover-foreground: 0 0% 3.9%;*/
/*    --primary: 0 72.2% 50.6%;*/
/*    --primary-foreground: 0 85.7% 97.3%;*/
/*    --secondary: 0 0% 96.1%;*/
/*    --secondary-foreground: 0 0% 9%;*/
/*    --muted: 0 0% 96.1%;*/
/*    --muted-foreground: 0 0% 45.1%;*/
/*    --accent: 0 0% 96.1%;*/
/*    --accent-foreground: 0 0% 9%;*/
/*    --destructive: 0 84.2% 60.2%;*/
/*    --destructive-foreground: 0 0% 98%;*/
/*    --border: 0 0% 89.8%;*/
/*    --input: 0 0% 89.8%;*/
/*    --ring: 0 72.2% 50.6%;*/
/*    --radius: 0.3rem;*/
/*  }*/

/*  .dark {*/
/*    --background: 0 0% 3.9%;*/
/*    --foreground: 0 0% 98%;*/
/*    --card: 0 0% 3.9%;*/
/*    --card-foreground: 0 0% 98%;*/
/*    --popover: 0 0% 3.9%;*/
/*    --popover-foreground: 0 0% 98%;*/
/*    --primary: var(--cardinal-red);*/
/*    --primary-foreground: 0 85.7% 97.3%;*/
/*    --secondary: 0 0% 14.9%;*/
/*    --secondary-foreground: 0 0% 98%;*/
/*    --muted: 0 0% 14.9%;*/
/*    --muted-foreground: 0 0% 63.9%;*/
/*    --accent: 0 0% 14.9%;*/
/*    --accent-foreground: 0 0% 98%;*/
/*    --destructive: 0 62.8% 30.6%;*/
/*    --destructive-foreground: 0 0% 98%;*/
/*    --border: 0 0% 14.9%;*/
/*    --input: 0 0% 14.9%;*/
/*    --ring: 0 72.2% 50.6%;*/
/*  }*/
/*}*/

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 72.2% 50.6%;
    --radius: 0.3rem;

    --crimson: 0 100% 49%;
    --aqua-blue: 180 100% 56%;
    --cardinal-red: 344 85% 46%;
    --sky-blue: 198 70% 73%;
    --prussian-blue: 219 96% 19%;
    --navy-blue: 224 40% 22%;
    --cobalt-blue: 212 61% 37%;
    --pine-green: 173 70% 35%;
    --midnight-black: 0 0% 7%;
    --text-subdued: 0 0% 53%;
    --text-default: 0 0% 100%;
    --text-black: 0 0% 0%;

    --crimson-rgb: #FC0000;
    --aqua-blue-rgb: #20FFFE;
    --cardinal-red-rgb: #D91248;
    --sky-blue-rgb: #8ACDEA;
    --prussian-blue-rgb: #02225D;
    --navy-blue-rgb: #222E50;
    --cobalt-blue-rgb: #255C99;
    --pine-green-rgb: #1B998B;
    --midnight-black-rgb: #121212;
    --text-subdued-rgb: #878787;
    --text-normal-rgb: #ffffff;
  }

  .dark {
    --background: var(--midnight-black);
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: var(--cardinal-red);
    --primary-foreground: 0 0% 100%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 39.8%;
    --input: 0 0% 29.8%;
    --ring: 0 72.2% 50.6%;
    --radius: 0.3rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
}

@layer components {
  .blog-content h1,
  .blog-content h2,
  .blog-content h3,
  .blog-content h4,
  .blog-content h5,
  .blog-content h6 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding-top: 18px;
    margin-bottom: 18px;
  }

  .blog-content .first {
    padding-top: 0;
  }

  .blog-content p {
    font-size: 18px;
    line-height: 32px;
    opacity: 0.9;
    margin-bottom: 18px;
  }

  .blog-content .date {
    font-size: 14px;
    line-height: 24px;
    opacity: 0.9;
    margin-bottom: 4px;
  }
}


/*mark {*/
/*  color: hsl(0 0% 100%);*/
/*}*/

/*mark {*/
/*  --highlighted: 1;*/
/*  !*--highlight: hsl(344 45% 46%);*!*/
/*  --highlight: hsl(173, 70%, 35%);*/
/*  background: transparent;*/
/*}*/

/*@supports (animation-timeline: view()) {*/
/*  mark {*/
/*    --highlighted: 0;*/
/*    animation: highlight steps(1) both;*/
/*    animation-timeline: view();*/
/*    animation-range: entry 100% cover 10%;*/
/*  }*/
/*}*/

/*mark span {*/
/*  background: linear-gradient(120deg, var(--highlight, lightblue) 50%, transparent 50%) 110% 0 / 200% 100% no-repeat;*/
/*  background-position: calc((1 - var(--highlighted)) * 110%) 0;*/
/*  transition: background-position 3s;*/
/*}*/

/*@keyframes highlight {*/
/*  to {*/
/*    --highlighted: 1;*/
/*  }*/
/*}*/
